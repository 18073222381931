import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Footer from "views/Footer";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";

import "../style/main.scss";
import PVCDograma from "components/PVCDograma";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query PVCSidingQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          anchor
          brand
          pages {
            gallery
            home
            aluminiumWindows
            pvcWindows
            products
            frontDoors
            verticalBlinds
            rollerBlinds
            externalBlinds
            securityShutters
            sectionalDoors
            interiorDoors
            sunshades
          }
          copyright
          header
          social {
            facebook
          }
          title
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const PVCDogramaPage = ({ data, pathContext: { langKey, defaultLang, langTextMap } }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { navBarNode, anchors, footerNode, pvcWindows } = breakDownAllNodes(nodes);

  let langSelectorPart;
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }

  return (
    <>
      <SEO lang={langKey} title="ПВЦ (PVC) Дограма" keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        extraItems={langSelectorPart}
        pathContext={{ langKey, defaultLang }}
      />
      <div className="bg-light pb-3">
        <PVCDograma frontmatter={pvcWindows.frontmatter} />
      </div>
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

PVCDogramaPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

PVCDogramaPage.defaultProps = {
  pathContext: {
    langKey: "bg",
    defaultLang: "bg",
    langTextMap: {
      en: "English",
      bg: "Български",
    },
  },
};

export default PVCDogramaPage;
