import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import Image from "./Image";

const PVCDograma = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="pvc_dograma/1.jpg"
              alt="PVC дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="pvc_dograma/2.jpg"
              alt="PVC дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="pvc_dograma/3.jpg"
              alt="PVC дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="d-flex justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#pvc_dograma" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <section className="col-lg-6">
        <b>Предимства на PVC прозорци и врати</b>
        <ul>
          <li>
            Благодарение на по-ниския коефицент на топлопроводимост на PVC профила, той по-успешно
            задържа топлината в помещението (доколкото това зависи от него, тъй като той е от 15% до
            20% от площта на прозореца).
          </li>
          <li>
            По-лесен за монтаж и изработка благодарение на факта, че е по-мек и гъвкав материал в
            сравнение с метала.
          </li>
          <li>
            PVC дограмата изисква минимална поддръжка - измива се с мека гъба/кърпа и сапун, не са
            необходими препарати.
          </li>
          <li>
            PVC профилите са по-евтини за изработка, което влияе и на крайната цена на дограмата.
          </li>
          <li>PVC кондензира влагата по-трудно от метала.</li>
        </ul>
      </section>
      <section className="col-12">
        <b>Видове</b>
        <ul>
          <li>Широка цветова гама на PVC профилите</li>
          <li>Висококачествени PVC профили с различни широчини (брой камери)</li>
          <li>
            Богат избор на стъклопакети (различни цветови отенъци, четири сезона, к-стъкло и др.).
            Оптимална дебелина.
          </li>
          <li>Към вашите врати и прозорци предлагаме различни модели комарници.</li>
        </ul>
      </section>
    </div>
  );
};

PVCDograma.propTypes = {
  frontmatter: PropTypes.object,
};

PVCDograma.defaultProps = {
  frontmatter: {},
};

export default PVCDograma;
